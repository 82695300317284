import { React, } from 'react';
import { ReactTyped } from 'react-typed';

import '../App.css';
import BASE_RATE from '../constants';



const NavigationBar = ({ currentPage }) => {

    const isCurrentPage = (page) => currentPage === page;

    return (
        <div className='container navbar'
        style={{
            //border: '1px solid black',
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            }}>

            <h3 className='nav-item'
            style={{ fontStyle: isCurrentPage('/about') ? 'italic': 'normal' }}>
                <a className='nav-link'
                onClick={() => window.location.href = '/about'}>
                    {currentPage === '/' ? 
                    <ReactTyped 
                    strings={['About']} 
                    typeSpeed={BASE_RATE} startDelay={14 * BASE_RATE} showCursor={false} />
                    : 'About'}
                </a>
            </h3>

            <h3 className='nav-item'
            style={{ fontStyle: isCurrentPage('/portfolio') ? 'italic': 'normal' }}>
                <a className='nav-link'
                onClick={() => window.location.href = '/portfolio'}>
                    {currentPage === '/' ?
                    <ReactTyped 
                    strings={['Portfolio']} 
                    typeSpeed={BASE_RATE} startDelay={30 * BASE_RATE} showCursor={false} />
                    : 'Portfolio'}
                </a>
            </h3>

            <h3 className='nav-item'
            style={{ fontStyle: isCurrentPage('/projects') ? 'italic': 'normal' }}>
                <a className='nav-link'
                onClick={() => window.location.href = '/projects'}>
                    {currentPage === '/' ?
                    <ReactTyped 
                    strings={['Projects']} 
                    typeSpeed={BASE_RATE} startDelay={50 * BASE_RATE} showCursor={false} />
                    : 'Projects'}
                </a>
            </h3>



            <h3 className='nav-item'
            style={{ fontStyle: isCurrentPage('/contact') ? 'italic': 'normal' }}>
                <a className='nav-link'
                onClick={() => window.location.href = '/contact'}>
                    {currentPage === '/' ?
                    <ReactTyped 
                    strings={['Contact']} 
                    typeSpeed={BASE_RATE} startDelay={70 * BASE_RATE} showCursor={false} />
                    : 'Contact'}
                </a>
            </h3>

        </div>
    )


};


export default NavigationBar;