
import { React } from 'react';
import { ReactTyped } from 'react-typed';

import NavigationBar from './Nav';

import BASE_RATE from '../constants';



const Header = ({ currentPage }) => {


    return (
        <div className='edge-container header-container' 
        style={{ 
            display: 'flex', justifyContent: 'flex-start', 
        }}>

            {/* first - a container for space */}
            <div className='container' style={{width: '7%'}} />

            {/* first the website title */}
            <div className='container header-text'
            style={{ minWidth: '28%', userSelect: 'none', padding: '0px',
                display: 'flex', justifyContent: 'flex-start'
              }}>
                <h1 
                style={{ cursor: 'pointer' }}
                onClick={() => window.location.href = '/'}
                >
                {currentPage === '/' ? 
                <ReactTyped strings={['Jimmy Banta']} typeSpeed={BASE_RATE} showCursor={false} />   
                : 'Jimmy Banta'} 
                </h1>
            </div>

            {/* another container for space */}
            <div className='container' style={{width: '25%'}} />


          {/* then the navbar */}
          <div className='container' 
          style={{ minWidth: '40%', alignItems: 'center', 
            display: 'flex', justifyContent: 'left', 
           }}
          >
            <NavigationBar 
            currentPage={currentPage}/>
          </div>
        </div>
    )


};

export default Header;