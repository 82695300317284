import { render } from '@testing-library/react';
import { React } from 'react';
import { ReactTyped } from 'react-typed';

const TYPING_RATE = 1;


const paragraphs = [
    'I studied Film and Math at NYU.',

    `While there, thinking I wanted to work in the documentary industry, 
    I had internships on video teams at CNN and The New York Times.`,

    'After graduating in 2020, I worked as a Calculus tutor and freelance writer.',

    `In 2021, after becoming interested in AI, and specifically AI safety, 
    I started teaching myself software engineering and Machine Learning.`,

    `A year later, I got a job at SpectraPass, a small healthtech startup.
    For a year and a half, I managed every aspect of their tech stack - I trained ML models and conducted data analysis work,
    I designed custom ML software, and I built their entire web system, handling frontend, backend, and deployment.`,

    `I'm currently doing research integrating Generative AI into a cloud spreadsheet engine for Aqaix, a climate/resilience finance startup.`,
]

const skills = [
    ['Languages', 'Python, Javascript, HTML, CSS'],
    ['Machine Learning', 'PyTorch, scikit-learn, H2O.ai, Deep Learning, Binary Classification, Regression'],
    ['ML Model Types', 'Neural Networks, Random Forests, GBMs, Logistic Regression, SVMs '],
    ['Generative AI', 'NLP, Transformers, LangChain, Anthropic Claude API, Google Gemini API'],
    ['AI Explainability', 'SHAP, LIME, Mechanistic Interpretability'],
    ['Data Science', 'NumPy, SciPy, pandas, matplotlib'],
    ['Web Development', 'React, Django, SQL, PostgreSQL, Celery, Gunicorn, daphne, nginx'],
    ['Amazon Web Services (AWS)', 'VPC, EC2, RDS, S3, Lambda, Route 53, Cloudfront, SES']
]


const About = () => {


    const renderSkills = () => {

        return (
            <div>
                {skills.map((skill, index) => {
                    return (
                        <div className='container'>
                            <div classname='container' 
                            style={{
                                display: 'flex', flexDirection: 'row',
                                justifyContent: 'flex-start',
                                
                                //border: '1px solid black'
                                }}>

                                <div className='container paragraph-text'
                                style={{
                                    width: '20%',
                                    textAlign: 'right',  
                                    fontStyle: 'italic',
                                }}>
                                    {skill[0]}
                                </div>

                                <div className='container paragraph-text'
                                style={{
                                    width: '80%',
                                    paddingLeft: '20px',
                                }}>
                                    {skill[1]}
                                </div>
                            </div>
                            <div style={{height: '20px'}}/>
                        </div>
                    )
                })}
            </div>
        )

    };
        



    return (
        <div className='container about-container' 
        style={{ 
        textAlign: 'left', lineHeight: '1.5' }}>


            {paragraphs.map((paragraph) => {

                return (
                    <div className='paragraph-text'>
                        {paragraph}
                        <div style={{height: '35px'}}/>
                    </div>
                )
            })}

            <div className='paragraph-text'>

            Over the past two years, I've participated in The Center for AI Safety's&nbsp;

            <a 
            className='link-text'
            style={{fontStyle: 'italic'}}
            href='https://course.mlsafety.org/about' target='_blank' rel='noopener noreferrer'>
            Introduction to ML Safety 
            </a>

            &nbsp;course, in BlueDot Impact's&nbsp;

            <a 
            className='link-text'
            style={{fontStyle: 'italic'}}
            href='https://aisafetyfundamentals.com/alignment/' target='_blank' rel='noopener noreferrer'>
            AI Safety Fundamentals: Alignment 
            </a>

            &nbsp;course, and in MIT's&nbsp;

            <a
            className='link-text'
            style={{fontStyle: 'italic'}}
            href='https://prolearn.mit.edu/artificial-intelligence-pharma-and-biotech' target='_blank' rel='noopener noreferrer'>
            AI in Pharma and Biotech
            </a>

            &nbsp;course.




            </div>
            <div style={{height: '20px'}}/>

            <h3 className='about-header'>Technical Skills</h3>
            {renderSkills()}

            <div style={{height: '200px'}}/>
        </div>
    )


};


export default About;
