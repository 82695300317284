import { React, } from 'react';
import { ReactTyped } from 'react-typed';

import BASE_RATE from '../constants';


const landingMessageOne = 'Welcome!';
const landingMessageTwo = 'My name is Jimmy, and I\'m a software and machine learning engineer.';



const LandingText = () => {


    return (
        <div className='container welcome-text' 
        style={{ 
            
         }}>

            <div>
                <ReactTyped
                strings={[landingMessageOne]}
                typeSpeed={BASE_RATE}
                startDelay={150 * BASE_RATE}
                showCursor={false}
                />
            </div>
            <div style={{height: '40px'}}/>
            <div>
                <ReactTyped
                strings={[landingMessageTwo]}
                typeSpeed={BASE_RATE}
                startDelay={160 * BASE_RATE}
                showCursor={false}
                />
            </div>

            
            

        </div>

        
    )

};


export default LandingText;