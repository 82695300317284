import { React, useState } from 'react';
import { ReactTyped } from 'react-typed';

import CrashGif from '../Components/CrashGif';
import BASE_RATE from '../constants';

import '../App.css';


const headerOne = 'AI-ML-Data Analysis';
const headerTwo = 'Web Development';

const Portfolio = () => {

    const [initial, setInitial] = useState(true);

    const [mlExpanded, setMlExpanded] = useState(true);

    const [softwareExpanded, setSoftwareExpanded] = useState(true);

    const toggleMlExpanded = () => {
        setInitial(false);
        setMlExpanded(!mlExpanded);
    };

    const toggleSoftwareExpanded = () => {
        setInitial(false);
        setSoftwareExpanded(!softwareExpanded);
    };


    const renderArrow = (delay, groupVar) => {

        if (initial) {
            return (
                <span style={{ marginLeft: '10px', userSelect: 'none', cursor: 'pointer' }}>
                    {groupVar ? 
                        <ReactTyped
                        strings={['▼']}
                        typeSpeed={BASE_RATE}
                        startDelay={delay}
                        showCursor={false}/> 
                        : 
                        <ReactTyped
                        strings={['▶']}
                        typeSpeed={BASE_RATE}
                        startDelay={delay}
                        showCursor={false}/> 
                        }  
                </span>
            )
        } else {
            return (
                <span style={{ marginLeft: '10px', userSelect: 'none', cursor: 'pointer' }}>
                    {groupVar ? '▼' : '▶'}
                </span>
            )
        }
    };

    const renderCovidTable = () => {

        return (
            <div className='container' 
            style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%',
            }}>

                <table 
                style={{ width: '50%', borderCollapse: 'collapse', marginTop: '20px' }}>
                    <thead>
                        <tr>
                            <th>Metric to Optimize</th>
                            <th>Metric Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Accuracy</td>
                            <td>93.7%</td>
                        </tr>
                        <tr>
                            <td>Avg of Sens. + Spec.</td>
                            <td>92.18%</td>
                        </tr>
                        <tr>
                            <td>PPV</td>
                            <td>88.89%</td>
                        </tr>
                        <tr>
                            <td>NPV</td>
                            <td>100%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )

    };

    const renderMicroIDTable = () => {

        return (
            <div className='container' 
            style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>

                <table 
                style={{ width: '50%', borderCollapse: 'collapse', marginTop: '20px' }}>
                    <thead>
                        <tr>
                            <th>Microorganism</th>
                            <th>Accuracy</th>
                            <th>Sensitivity</th>
                            <th>Specificity</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Acinetobacter</td>
                            <td>97.16%</td>
                            <td>100%</td>
                            <td>97.11%</td>
                        </tr>
                        <tr>
                            <td>Bacillus</td>
                            <td>99.36%</td>
                            <td>99.23%</td>
                            <td>99.49%</td>
                        </tr>
                        <tr>
                            <td>E. Coli</td>
                            <td>83.52%</td>
                            <td>100%</td>
                            <td>82.84%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    };



    const renderMLPortfolio = () => {

        if (mlExpanded) {
            return (
                <div>

                    <div 
                    className='container' style={{display: 'flex'}}>
                        <div className='container' style={{minWidth: '9%'}} />
                        <div className='container'>
                            <h3>Spectrapass-ML</h3>
                            <p>
                                SpectraPass-ML was an AutoML program that wrapped around h2o.ai and 
                                added functionality such as optimizing by certain metrics,
                                four different validation methods, working with technical replicates of the same sample,
                                advanced model ranking and sorting, and more.
                            </p>
                            <p>
                                It allowed us to easily and efficiently train, validate, 
                                save, and deploy ML models suited to our use case.
                            </p>
                            <p>
                                I developed it because I was dissatisfied with our previous AutoML solution, and 
                                wanted to build something more efficient and high-performing.
                            </p>
                            
                        </div>
                    </div>

                    <div className='container' style={{display: 'flex'}}>
                        <div className='container'
                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',
                            }}>
                                <h3>
                                    Results
                                </h3>
                                <p>
                                    SpectraPass-ML compared against two other AutoML systems
                                    that we had used in the past, on two different datasets:
                                </p>
                                <img className='portfolio-img'
                                src="/assets/sp-ml-covid361.png" alt="Covid-361 Results"/>
                                &nbsp;
                                <img 
                                id='llm-from-scratch'
                                className='portfolio-img'
                                src="/assets/sp-ml-covid1500.png" alt="Covid-1500 Results" />
                        </div>
                    </div>  

                    <div 
                    className='container' style={{display: 'flex'}}>
                        <div className='container' style={{minWidth: '9%'}} />
                        <div className='container'>
                            <a
                            href='https://github.com/jimmybanta/LLM-from-scratch'
                            className='link-text'
                            target='_blank' rel='noreferrer'
                            >
                                <h3
                                id='covid-assay'
                                >LLM-from-scratch</h3>
                            </a>
                            
                            <p>
                                An ongoing project to implement and train a transformer-based 
                                Large Language Model (LLM) from scratch, using Python.
                            </p>
                            <p>
                                Arose from a desire to gain a deep understanding of how LLM's work. 
                            </p>
                            
                        </div>
                    </div>




                    <div 
                    className='container' style={{
                        display: 'flex'}}>
                        <div className='container' style={{minWidth: '9%'}} />
                        <div className='container'>
                            <h3>Covid Assay</h3>
                            <p>
                                Using proprietary data, I trained ML models to predict the presence of Covid-19
                                in subjects based on their mass spectrometry data taken from nasal swabs.
                            </p>
                            <p>
                                See below for the metric values of models optimized for those metrics.
                            </p>
                        </div>
                    </div>

                    <div className='container' style={{display: 'flex', flexDirection: 'column'}}>
                        <div className='container'
                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',
                            }}>
                                <h3
                                id='micro-id'
                                >Results</h3>
                                {renderCovidTable()}
                        </div>
                    </div>

                    <div 
                    className='container' style={{display: 'flex', 
                    //border: '1px solid black'
                    }}>
                        <div 
                        
                        className='container' style={{minWidth: '9%'}} />
                        <div className='container'>
                            <h3>MicroID</h3>
                            <p>
                                Using publicly available mass spectrometry data, I trained ML models to predict
                                the presence of various microorganisms in proprietary samples.
                            </p>

                        </div>
                    </div>

                    <div className='container' style={{display: 'flex', flexDirection: 'column'}}>
                        <div className='container'
                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',
                            }}>
                                <h3 
                                id='spectrapass-client'
                                >Results</h3>
                                {renderMicroIDTable()}
                        </div>
                    </div>



                </div>
            )
        }

    };

    const renderSoftwarePortfolio = () => {

        if (softwareExpanded) {
            return (
                <div>

                    <div className='container' style={{
                        display: 'flex'}}>
                        <div className='container' style={{minWidth: '9%'}} />
                        <div className='container' >
                            <h3>SpectraPass Client System</h3>
                            <p>
                                The SpectraPass Client system allowed clients to easily and efficiently run, 
                                upload, and process their mass spectrometry data.
                            </p>
                            <p>
                                It included: 
                            </p>
                                <p
                                style={{
                                    marginLeft: '40px'
                                }}>
                                    A Lab app, where clients could upload MALDI-TOF Mass Spectrometry plates, to run on the machine.
                                </p>
                                <p
                                style={{
                                    marginLeft: '40px'
                                }}>
                                    A Dashboard, where clients could view, download, filter, sort, and analyze their data. 
                                </p>
                                <p
                                style={{
                                    marginLeft: '40px'
                                }}>
                                    A Data app, where clients could process their raw data.
                                </p>
                                <p
                                style={{
                                    marginLeft: '40px'
                                }}>
                                    A Sandbox app, where clients could use our custom data processing and screening tools on outside data.
                                </p>
                                <p
                                style={{
                                    marginLeft: '40px'
                                }}>
                                    An ML app, where clients could manage their ML models and use them to analyze their data.
                                </p>
                                <p
                                style={{
                                    marginLeft: '40px'
                                }}>
                                    A MicroID app, where clients could run their data through a (prototype) Microbial Identification database.
                                </p>
                            <p 
                            style={{
                                marginTop: '30px'
                            }}>
                                Build specifications:
                            </p>
                                <p
                                style={{
                                    marginLeft: '40px'
                                }}>
                                    <span
                                    style={{
                                        fontStyle: 'italic'
                                    }}>Backend:</span> Built with Django and Django Rest Framework, PostgreSQL, and Celery.
                                </p>
                                <p
                                style={{
                                    marginLeft: '40px'
                                }}>
                                    <span
                                    style={{
                                        fontStyle: 'italic'
                                    }}>Frontend:</span> Built with React and Bootstrap, used JSON Web Tokens for authentication.
                                </p>
                                <p
                                style={{
                                    marginLeft: '40px'
                                }}>
                                    <span
                                    style={{
                                        fontStyle: 'italic'
                                    }}>
                                        Deployment:</span> Deployed on AWS, using EC2 instances with Load Balancers to host the backend, 
                                    S3 + CloudFront to host the frontend, RDS to host the database, S3 for file storage,
                                    Secrets Manager and Parameter Store for configuration,
                                    Route 53 for DNS, and SES for email services.

                                </p>
                        </div>
                    </div>

                    <div className='container' 
                        style={{alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                            <p>
                                See the following video for a full demo of the system: 
                            </p>
                            <video 
                            className='portfolio-img'
                                     controls>
                                <source 
                                src="/assets/client-demo.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                    </div>

                    <div className='container' style={{height: '50px'}} />
                    <div className='container' style={{
                        display: 'flex'}}>
                        <div className='container' style={{minWidth: '9%'}} />
                        <div className='container' >
                            <h3>Crash</h3>
                            <p>
                                Crash is an AI-generated, interactive story game.
                            </p>
                            <p>
                                It was built using Django + React, and deployed on AWS. 
                                It uses Anthropic's Claude family of models to generate the story, 
                                with automated summarization and prompt caching to decrease latency and cost.
                            </p>
                            <p>
                                You can see the code for Crash&nbsp;
                                <a 
                                className='link-text'
                                href='https://github.com/jimmybanta/crash-the-game' 
                                target='_blank' rel='noreferrer'
                                >
                                here
                                </a>
                                .
                            </p>
                        </div>
                    </div>

                    <div className='container'
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <a 
                            style={{
                                height: '100%', width: '50%', 
                                minWidth: '300px',
                                display: 'flex', justifyContent: 'center', alignItems: 'center',
                                margin: '20px',
                                cursor: 'pointer',
                            }}
                            href='https://crashthegame.com' target='_blank' rel='noreferrer'>
                                <img 
                                style={{width: '100%', height: '100%'}}
                                src='/assets/crash.jpeg' alt='crash' className='portfolio-img'/>       
                            </a>                     
                    </div>

                    <div className='container' style={{height: '50px'}} />
                    <div className='container' style={{
                        display: 'flex'}}>
                        <div className='container' style={{minWidth: '9%'}} />
                        <div className='container' >
                            <h3>Dudo Perudo</h3>
                            <p>
                                Dudo Perudo is an AI-integrated web-based version of Perudo, a dice game of bluffing and deception.
                            </p>
                            <p>
                                It was built using Django + React, and deployed on AWS. 
                                It utilizes an intelligent Perudo engine that plays against the user, and
                                it integrates with Google's Gemini family of models to generate a real-time, interactive chat. 

                            </p>
                            <p>
                                You can see the code for Dudo Perudo&nbsp;
                                <a 
                                className='link-text'
                                href='https://github.com/jimmybanta/dudo-perudo' 
                                target='_blank' rel='noreferrer'
                                >
                                here
                                </a>
                                .
                            </p>
                        </div>
                    </div>

                    <div className='container'
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <a 
                            style={{
                                height: '100%', width: '50%', 
                                minWidth: '300px',
                                display: 'flex', justifyContent: 'center', alignItems: 'center',
                                margin: '20px',
                                cursor: 'pointer',
                            }}
                            href='https://dudoperudo.com' target='_blank' rel='noreferrer'>
                                <img 
                                style={{width: '100%', height: '100%'}}
                                src='/assets/dudoperudo.png' alt='dudoperudo' className='portfolio-img'/>       
                            </a>                     
                    </div>

                </div>
    
                    
            )
        }
    };



    return (
        <div className='container paragraph-text' 
        style={{ 
            margin: '100px', 
            marginTop: '50px',
        textAlign: 'left', lineHeight: '1.5'}}>
            <div id='spectrapass-ml' 
            style={{height: '50px'}}
            />

            <div className='container'>

                <div className='container'
                    style={{display: 'flex', width: '100%', height: '100%',
                    }}>
                        <div className='container' 
                        style={{alignItems: 'center', justifyContent: 'center', display: 'flex', width: '7%'}}
                        onClick={toggleMlExpanded}>
                            {renderArrow(0, mlExpanded)}
                        </div>
                        <div className='container' style={{width: '2%'}} />
                        <div className='container'>
                            <div onClick={toggleMlExpanded} 
                                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', userSelect: 'none' }}>
                                <h2>
                                    {initial ? <ReactTyped
                                    strings={[headerOne]}
                                    typeSpeed={BASE_RATE}
                                    startDelay={BASE_RATE}
                                    showCursor={false}/>  
                                    : headerOne}
                                </h2>

                            </div>
                        </div>
                        <div className='container'>
                            <h2 style={{visibility: 'hidden'}}>&nbsp;</h2>
                        </div>
                    
                </div>

                {renderMLPortfolio()}

            </div>

            <div className='container'>
                <div className='container'
                    style={{display: 'flex', width: '100%', height: '100%'}}>
                        <div className='container' 
                        style={{alignItems: 'center', justifyContent: 'center', display: 'flex', width: '7%'}}
                        onClick={toggleSoftwareExpanded}>
                            {renderArrow(headerOne.length * BASE_RATE + 250, softwareExpanded)}
                        </div>
                        <div className='container' style={{width: '2%'}} />
                        <div className='container'>
                            <div onClick={toggleSoftwareExpanded} 
                                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', userSelect: 'none' }}>
                                <h2>
                                    {initial ? <ReactTyped
                                    strings={[headerTwo]}
                                    typeSpeed={BASE_RATE}
                                    startDelay={headerOne.length * BASE_RATE + 250 + BASE_RATE}
                                    showCursor={false}/>  
                                    : headerTwo}
                                </h2>

                            </div>
                        </div>
                        <div className='container'>
                            <h2 style={{visibility: 'hidden'}}>&nbsp;</h2>
                        </div>
                </div>

                {renderSoftwarePortfolio()}

            </div>

            <div className='container' style={{height: '300px'}}/>
        </div>
    )


};


export default Portfolio;