import { React } from 'react';

import ContactForm from './ContactForm';


const Contact = () => {


    return (
        <div className='container contact-container'>
            <h1 className='contact-me'
            style={{userSelect: 'none'}}>Contact Me</h1>
            <div className='contact-card'
            style={{
                        border: '#000000 solid 1px',
                        backgroundColor: '#515242',
                        borderRadius: '20px'}}>
                <ContactForm />
            </div>
            
        </div>
    )


};


export default Contact;