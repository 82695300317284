import { React, useState, useEffect } from 'react';
import { ReactTyped } from 'react-typed';

import BASE_RATE from '../constants';


const Footer = ({ currentPage }) => {

    const [linkedInVisible, setLinkedInVisible] = useState(false);
    const [githubVisible, setGithubVisible] = useState(false);

    // timers for when to display icons
    useEffect(() => {

        if (!(currentPage === '/')) {
            setLinkedInVisible(true);
            return;
        }

        const linkedInTimer = setTimeout(() => {
            setLinkedInVisible(true);
        }, 1600); // Change the delay time as needed (3000ms = 3 seconds)

        return () => clearTimeout(linkedInTimer); // Cleanup the timer on component unmount

    }, [currentPage]);

    useEffect(() => {

        if (!(currentPage === '/')) {
            setGithubVisible(true);
            return;
        }

        const githubTimer = setTimeout(() => {
            setGithubVisible(true);
        }, 1800); // Change the delay time as needed (6000ms = 6 seconds)

        return () => clearTimeout(githubTimer); // Cleanup the timer on component unmount

    }, [currentPage]);


    return (
        <div className='edge-container footer-container'
        style={{ display: 'flex', flexDirection: 'row',
            alignItems: 'center', justifyContent: 'center',
            padding: '0px'}}>

            <div className='container footer'
            style={{
                display: 'flex', flexDirection: 'row', 
                alignItems: 'center',
                justifyContent: 'flex-start',
            }}>

                {/* <h3 className='nav-item'>
                    <a className='nav-link'
                    href='https://www.linkedin.com/in/jimmy-banta-99447a175/' target='_blank' rel='noopener noreferrer'>
                        {currentPage === '/' ?
                        <ReactTyped 
                        strings={['LinkedIn']} 
                        typeSpeed={BASE_RATE} startDelay={52 * BASE_RATE} showCursor={false} />
                        : 'LinkedIn'}
                    </a>
                </h3> */}

                <div className='container'
                style={{
                    width: '25%',
                    display: 'flex',
                    justifyContent: 'center',

                }}
                >
                    <h3 className='nav-item'>
                        <a className='nav-link'
                        target='_blank' rel='noreferrer'
                        href='https://jimmybanta.substack.com/'
                        >
                            {currentPage === '/' ?
                            <ReactTyped 
                            strings={['Blog']} 
                            typeSpeed={BASE_RATE} startDelay={85 * BASE_RATE} showCursor={false} />
                            : 'Blog'}
                        </a>
                    </h3>
                </div>

                <div className='container'
                style={{
                    width: '25%',
                }}
                >

                    <h3 className='nav-item'
                    style={{
                        display: linkedInVisible ? 'block' : 'none',
                    }}>
                        <a className='nav-link'
                        href='https://www.linkedin.com/in/jimmy-banta-99447a175/' target='_blank' rel='noopener noreferrer'>
                            <img className='nav-icon' src='/assets/linkedin.png' alt='LinkedIn' />
                        </a>
                    </h3>
                </div>

                <div className='container'
                style={{
                    width: '25%',
                }}
                >

                    <h3 className='nav-item'
                    style={{
                        display: githubVisible ? 'block' : 'none',
                    }}>
                        <a className='nav-link'
                        href='https://github.com/jimmybanta/' target='_blank' rel='noopener noreferrer'>
                            <img className='nav-icon' src='/assets/github.png' alt='Github' />
                        </a>
                    </h3>

                </div>


                {/* <h3 className='nav-item'>
                    <a className='nav-link'
                    href='https://www.linkedin.com/in/jimmy-banta-99447a175/' target='_blank' rel='noopener noreferrer'>
                        {currentPage === '/' ?
                        <ReactTyped 
                        strings={['Github']} 
                        typeSpeed={BASE_RATE} startDelay={52 * BASE_RATE} showCursor={false} />
                        : 'Github'}
                    </a>
                </h3> */}

                <div className='container'
                style={{
                    width: '25%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
                >
                    
                    <h3 className='nav-item'>
                        <a className='nav-link'
                        href='/assets/Banta-Resume.pdf' download>
                            {currentPage === '/' ?
                            <ReactTyped 
                            strings={['Resume']} 
                            typeSpeed={BASE_RATE} startDelay={125 * BASE_RATE} showCursor={false} />
                            : 'Resume'}
                        </a>
                    </h3>

                </div>

            


            </div>

            
        </div>

    )

};


export default Footer;