
import { React, useRef, useState } from 'react';

const TYPING_RATE = 40;

const title = 'CRASH';

const description = 'an ai-generated, interactive story game';

const CrashGif = () => {

    const [showTitle, setShowTitle] = useState('');
    const [showDescription, setShowDescription] = useState('');

    const timeoutIds = useRef([]);

    const handleMouseEnter = () => {

        let delay = 0;

        // Clear any existing timeouts
        timeoutIds.current.forEach(timeoutId => clearTimeout(timeoutId));
        timeoutIds.current = [];

        // Iterate through the title string
        for (const char of title) {
            const timeoutId = setTimeout(() => {
                setShowTitle((prev) => prev + char);
            }, delay);
            timeoutIds.current.push(timeoutId);
            delay += TYPING_RATE;
        }

        // Iterate through the description string
        for (const char of description) {
            const timeoutId = setTimeout(() => {
                setShowDescription((prev) => prev + char);
            }, delay);
            timeoutIds.current.push(timeoutId);
            delay += TYPING_RATE;
        }
    };

    const handleMouseLeave = () => {
        // Clear any existing timeouts
        timeoutIds.current.forEach(timeoutId => clearTimeout(timeoutId));
        timeoutIds.current = [];
        setShowTitle('');
        setShowDescription('');
    };


    return (
        <a 
        className='game'
        href='https://crashthegame.com' target='_blank' rel='noreferrer'>
            <div className='image-container'
            onMouseEnter={() => {
                handleMouseEnter()
            }}
            onMouseLeave={() => {
                handleMouseLeave()
            }}
            >


                <img 
                src='/assets/crash.gif' 
                alt='crash' 
                className='hover-gif crash-gif'
                />

                <div className='overlay-text'
                style={{width: '80%',
                }}>

                    <h2>
                        {showTitle}
                    </h2>
                    <p
                    >
                        {showDescription}
                    </p>                    
                    
                </div>

            </div>
        </a>
    )


};


export default CrashGif;