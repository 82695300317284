import { React } from 'react';

import CrashGif from '../Components/CrashGif';
import DudoPerudoGif from '../Components/DudoPerudoGif';


const Games = () => {


    return (
        <div className='container games-container' 
        style={{ }}>

            <CrashGif />
            <DudoPerudoGif />

            



        </div>
    )


};


export default Games;